<template>
  <div class="wap-search" :class="pageType == 3 ? 'white' : ''">
    <div class="top-search flex-center">
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="input flex-center flex-1">
        <i class="el-icon-search"></i>
        <Field
          v-model="keyword"
          :placeholder="$t('qing-shu-ru-shang-pin')"
          clearable
        ></Field>
      </div>
      <div class="btn" @click="search">{{ $t('sou-suo-0') }}</div>
    </div>

    <div class="result-list" v-if="isInit">
      <List
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.noMore')"
        @load="onLoad"
        v-if="resultList.length > 0"
      >
        <goodsList
          v-if="pageType != 3"
          :list="resultList"
          :showEdit="true"
          type="seller"
          @edit="editProduct"
        ></goodsList>
        <userGoodsList v-else :list="resultList"></userGoodsList>
      </List>

      <Empty v-if="resultList.length == 0" :description="$t('zan-wu-shu-ju')"></Empty>
    </div>

    <div class="history" v-else>
      <div class="flex-center-between">
        <div>{{ $t('li-shi-sou-suo') }}</div>
        <div class="flex-center" @click="removeHistory">
          <i class="el-icon-delete"></i>
          {{ $t('shan-chu') }}
        </div>
      </div>

      <div class="history-list flex-wrap">
        <div
          class="tag"
          v-for="(item, i) in historyList"
          :key="i"
          @click="chooseHistory(item)"
        >
          {{ item }}
        </div>
      </div>

      <Empty v-if="historyList.length == 0" :description="$t('zan-wu-shu-ju')"></Empty>
    </div>
  </div>
</template>
<script>
import { Button, List, Field, Empty, Dialog } from 'vant'
import headerBar from '@/components/header'
import { productList, shopProductList, addProduct } from '@/api/shop'
import { getUserId } from '@/utils/auth'
import goodsList from '@/components/goodsList/wapList.vue'
import userGoodsList from '@/components/goodsList/wapUserList.vue'
export default {
  components: {
    Button,
    goodsList,
    userGoodsList,
    headerBar,
    List,
    Field,
    Empty
  },
  data() {
    return {
      pageType: '', // 1: 卖家店铺商品搜索， 2： 卖家产品库搜索, 3：买家搜索
      keyword: '',
      resultList: [],
      page: {
        current: 1,
        size: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1,
      historyList: []
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    this.pageType = this.$route.query.type
    let history = sessionStorage.getItem('searchHistory')
    if (history) {
      this.historyList = JSON.parse(history)
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    search() {
      this.page.current = 1
      let history = this.historyList.concat([this.keyword])
      sessionStorage.setItem('searchHistory', JSON.stringify(history))
      this.init()
    },
    chooseHistory(data) {
      this.keyword = data
      this.search()
    },
    removeHistory() {
      this.historyList = []
      sessionStorage.setItem('searchHistory', '')
    },
    onLoad() {
      this.page.current += 1
      this.init(true)
    },
    async init(isAdd) {
      this.loading = true
      let res = ''
      if (this.pageType == 1) {
        res = await shopProductList({
          current: this.page.page,
          pageSize: this.page.limit,
          Title: this.keyword,
          UId: parseFloat(getUserId())
        })
      } else if (this.pageType == 2) {
        res = await productList({
          current: this.page.current,
          pageSize: this.page.size,
          title: this.keyword
        })
      } else if (this.pageType == 3) {
        res = await shopProductList({
          current: this.page.page,
          pageSize: this.page.limit,
          Title: this.keyword
        })
      }
      if (res) {
        if (isAdd) {
          this.resultList = this.resultList.concat(res.data.Items)
        } else {
          this.resultList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.current) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      } else {
        this.isInit = true
        this.loading = false
      }
    },
    editProduct(data) {
      if (this.pageType == 1) {
        this.$router.push({
          name: 'wapEditProduct',
          query: {
            ids: data.ID,
            status: data.Status,
            type: 'one'
          }
        })
      } else {
        Dialog.confirm({
          title: this.$t('que-ren'),
          message: this.$t('que-ren-tian-jia-gai-shang-pin')
        }).then(() => {
          addProduct({
            ProductID: [parseFloat(data.ID)]
          }).then((res) => {
            if (res.code == 0) {
              this.$toast(this.$t('tian-jia-cheng-gong'))
            } else {
              this.$toast(res.msg)
            }
          })
        })
      }
    }
  }
}
</script>