<template>
  <div class="wap-user-goods-list flex-wrap">
    <div
      class="list"
      @click="toDetail(item)"
      v-for="(item, i) in list"
      :key="i"
    >
      <div class="img flex-center">
        <img :src="item.MainImage" alt="" />
      </div>
      <div class="flex-1 info">
        <div class="price">${{ item.SalePrice }}</div>
        <div class="count">
          <span>{{ $t("xiao-liang") }}{{ item.Sales }}</span>
        </div>
        <div class="title">
          {{ item.ProductTranslation ? item.ProductTranslation[0].Name : '' }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Checkbox } from 'vant'
export default {
  components: {
    Checkbox
  },
  data() {
    return {}
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showCheck: {
      type: Boolean,
      default: false
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    type: String
  },
  methods: {
    toDetail(data) {
      let name =
        this.type == 'seller' ? 'wapProductDetail' : 'wapUserProductDetail'
      this.$router.push({
        name: name,
        query: {
          id: data.ID
        }
      })
    },
    toEdit(data) {},
    checkGoods(data, i) {
      this.$emit('check', data.checked, i)
    }
  }
}
</script>